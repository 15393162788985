import React, { useState, useContext } from "react";
import {
    Col,
    Form,
    Row,
    Card,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import {
    faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import NotyfContext from "../../contexts/NotyfContext";
import {
    HelpTooltip
} from "../../assets/functions.js";
import { handleShareEmail } from "../../assets/functions"
import DropzoneComponent from "../Dropzone.js";
import { domainConfig } from "../../config.js";
import PopoverPicker from "../PopoverPicker.js";

const Gallery = ({
    fonts,
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
    handlePreviewSuccess,
    handlePreviewError,
}) => {
    const notyf = useContext(NotyfContext);
    const [props, setProps] = useState({
        backgroundColor: values.json.apresentation?.textSide?.background.color,
        textColor: values.json.apresentation?.textSide?.textColor
    });

    return (
        <>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Definições</h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"

                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        type="text"
                        name="titulo"
                        value={values.titulo}
                        onChange={handleChange}
                        isInvalid={!!errors.titulo}
                        isValid={touched.titulo && !errors.titulo}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.titulo}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex text-start">
                    <Form.Group sm={6}>
                        <Form.Check
                            className="mode"
                            checked={values.json.useMemzo.status ? true : false}
                            type="checkbox"
                            name={"json.useMemzo.status"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.useMemzo.status',
                                    values.json.useMemzo.status === true ? false : true
                                );
                            }}
                            label={<>Memzo AI <OverlayTrigger overlay={<Tooltip>Serviço de AI de reconhecimento facial. Permite aos participantes acessarem suas fotos</Tooltip>}><HelpTooltip icon={faExclamationCircle} /></OverlayTrigger></>}
                            isInvalid={!!errors.json?.useMemzo?.status}
                            feedback={errors.json?.useMemzo?.status}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                </div>
                {values.json.useMemzo.status && <Row className="text-start">
                    <Form.Group as={Col} md={6} className='mb-3'>
                        <Form.Label>Link da pasta - Google Drive <OverlayTrigger overlay={<Tooltip>Compartilhe o folder com o nosso email de serviço para liberar o upload de imagens</Tooltip>}><HelpTooltip icon={faExclamationCircle} /></OverlayTrigger><span className="badge btn-primary ms-2 pointer" onClick={() => handleShareEmail('flex-plataforma-de-aplicativos@plataforma-de-aplicativos.iam.gserviceaccount.com', notyf)}>Copiar email</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="json.useMemzo.value"
                            value={values.json.useMemzo.value}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            isInvalid={!!errors.json?.useMemzo?.value}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors.json?.useMemzo?.value}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>}
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Participante
                </h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela inicial</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"

                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.title.value"
                        value={values.json.title.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.title.status", false);
                            } else {
                                setFieldValue("json.title.status", true);
                            }
                            setFieldValue("json.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.title?.value}
                        isValid={touched?.json?.title?.value && !errors.json?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"

                >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.description.value"
                        value={values.json.description.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.description.status", false);
                            } else {
                                setFieldValue("json.description.status", true);
                            }
                            setFieldValue("json.description.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.description?.value}
                        isValid={touched?.json?.description?.value && !errors.json?.description?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.description?.value}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Modo apresentação</h5>
            </Form.Group>
            <Row className="mx-0">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"

                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.apresentation.textSide.title.value"
                        value={values.json.apresentation?.textSide?.title?.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.apresentation.textSide.title.status", false);
                            } else {
                                setFieldValue("json.apresentation.textSide.title.status", true);
                            }
                            setFieldValue("json.apresentation.textSide.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.apresentation?.textSide?.title?.value}
                        isValid={touched?.json?.apresentation?.textSide?.title?.value && !errors?.json?.apresentation?.textSide?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                >
                    <Form.Label>Tamanho fonte (M) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.h5"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.h5}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.h5}
                        isValid={touched.json?.apresentation?.textSide?.h5 && !errors.json?.apresentation?.textSide?.h5}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.h5}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                >
                    <Form.Label>Tamanho fonte (P) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.p"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.p}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.p}
                        isValid={touched.json?.apresentation?.textSide?.p && !errors.json?.apresentation?.textSide?.p}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.p}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="col-md-3 mb-3">
                    <Form.Label>Cor do texto</Form.Label>
                    <div className={`d-flex justify-content-start align-items-center ${errors?.json?.apresentation?.textSide?.textColor ? 'is-invalid' : ''}`}>
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.textColor"
                            value={values.json?.apresentation?.textSide?.textColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.textColor}
                            isValid={touched?.json?.apresentation?.textSide?.textColor && !errors?.json?.apresentation?.textSide?.textColor}
                        />
                        <PopoverPicker
                            color={props.textColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.textColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    textColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.textColor ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.textSide?.textColor ? errors?.json?.apresentation?.textSide?.textColor : ''}
                    </Form.Control.Feedback>
                </div>
            </Row>
            <Row className="mx-0 mb-3">
                <Form.Group
                    as={Col}
                    md="6">
                    <Form.Label>Tipo de plano de fundo</Form.Label>
                    <Form.Select
                        name="json.apresentation.textSide.background.type"
                        onChange={handleChange}
                        value={values.json.apresentation.textSide.background.type}
                        isInvalid={!!errors.json?.apresentation?.textSide?.background?.type}
                        isValid={touched.json?.apresentation?.textSide?.background?.type && !errors.json?.apresentation?.textSide?.background?.type}>
                        <option value={"color"}>
                            Cor
                        </option>
                        <option value={"image"}>
                            Imagem
                        </option>
                    </Form.Select>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.json?.apresentation?.textSide?.background?.type}</Form.Control.Feedback>
                </Form.Group>
            </Row>
            {values.json?.apresentation?.textSide?.background?.type !== 'color' ? <Row className="mb-3 mx-0">
                <BackgroundFormComponent touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} handlePreviewSuccess={handlePreviewSuccess} handlePreviewError={handlePreviewError}></BackgroundFormComponent>
            </Row> :
                <Row className="mx-0 mb-3">
                    <div className="col-md-3 mb-3">
                        <div className={`d-flex justify-content-start align-items-center ${errors?.json?.apresentation?.textSide?.background?.color ? 'is-invalid' : ''}`}>
                            <Form.Control
                                type="hidden"
                                name="json.apresentation.textSide.background.color"
                                value={values.json.apresentation.textSide.background.color}
                                isInvalid={!!errors?.json?.apresentation?.textSide?.background?.color}
                                isValid={touched?.json?.apresentation?.textSide?.background?.color && !errors?.json?.apresentation?.textSide?.background?.color}
                            />
                            <PopoverPicker
                                color={props.backgroundColor}
                                onChangeX={(e) => {
                                    setFieldValue("json.apresentation.textSide.background.color", e);
                                    setProps(prevState => ({
                                        ...prevState,
                                        backgroundColor: e
                                    }));
                                }}
                                classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.background?.color ? 'is-invalid' : ''}`}
                            />
                        </div>
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors?.json?.apresentation?.textSide?.background?.color ? errors?.json?.apresentation?.textSide?.background?.color : ''}
                        </Form.Control.Feedback>
                    </div>
                </Row>
            }
            <Row className="mx-0 mb-3">
                <Form.Group
                    as={Col}
                    md="6">
                    <Form.Label>Tipo de layout</Form.Label>
                    <Form.Select
                        onChange={(e) => {
                            if (e.target.value == 2) {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 40
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 60
                                );
                            } else {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 0
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 100
                                );
                            }
                        }}
                        value={values.json.apresentation?.imageSide?.value && values.json.apresentation?.imageSide?.value > 0 ? 2 : 1}>
                        <option value={1}>
                            Simples
                        </option>
                        <option value={2}>
                            Com banner
                        </option>
                    </Form.Select>
                </Form.Group>
            </Row>
            {
                values.json.apresentation.imageSide?.value > 0 &&
                <>
                    <Row className="mx-0 mb-3">
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"
                        >
                            <Form.Label>Tamanho do banner (%)</Form.Label>
                            <Form.Control
                                type="number"
                                name="json.apresentation.imageSide.value"
                                value={values.json.apresentation?.imageSide?.value}
                                onChange={(e) => {
                                    if (e.target.value === undefined || e.target.value === '') {
                                        e.target.value = 0
                                    }
                                    let textSideValue = 100 - e.target.value
                                    setFieldValue("json.apresentation.imageSide.value", e.target.value);
                                    setFieldValue("json.apresentation.textSide.value", textSideValue);
                                }}
                                isInvalid={!!errors.json?.apresentation?.imageSide?.value}
                                isValid={touched.json?.apresentation?.imageSide?.value && !errors.json?.apresentation?.imageSide?.value}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.apresentation?.imageSide?.value}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"

                        >
                            <Form.Label>Posição</Form.Label>
                            <Form.Select
                                onChange={handleChange}
                                name="json.apresentation.imageSide.position"
                                value={values.json.apresentation?.imageSide?.position}>
                                <option value={'start'}>
                                    Esquerda
                                </option>
                                <option value={'end'}>
                                    Direita
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mx-0 mb-3">
                        <Form.Group
                            as={Col}
                            md="6" className="mb-3">
                            <Card className="mb-3">
                                <Card.Body>
                                    <div className="text-center mb-3">
                                        <div className="d-flex justify-content-center mb-3">
                                            <Form.Label>Banner</Form.Label>
                                        </div>
                                    </div>
                                    <Form.Control
                                        type="hidden"
                                        name="json.apresentation.imageSide.background"
                                        value={values.json.apresentation?.imageSide?.background}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.apresentation?.imageSide?.background}
                                        isValid={touched?.json?.apresentation?.imageSide?.background && !errors?.json?.apresentation?.imageSide?.background}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.imageSide?.background ? "is-invalid" : ""}`}
                                            src={
                                                values.json.apresentation?.imageSide?.background !== "" && values.json.apresentation?.imageSide?.background !== null
                                                    ? domainConfig.imageServer +
                                                    "/customizacao/" +
                                                    values.customizacao_id +
                                                    "/" +
                                                    values.json.apresentation?.imageSide?.background
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.apresentation?.imageSide?.background}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="customizacao"
                                        id={values.customizacao_id}
                                        field="json.apresentation.imageSide.background"
                                    />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Row>
                </>
            }
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mt-3 mb-2">Logomarca</p>
                <Form.Group as={Col} sm={6} className='mb-3'>
                    <Form.Label>Largura (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.logoSize"
                        value={values.json.apresentation.configs.logoSize}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.logoSize}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.logoSize}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Grade de fotos</p>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Linhas</Form.Label>
                    <Form.Control
                        type="number"
                        step={1}
                        name="json.apresentation.configs.rows"
                        value={values.json.apresentation.configs.rows}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.rows}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.rows}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Colunas</Form.Label>
                    <Form.Control
                        type="number"
                        step={1}
                        name="json.apresentation.configs.columns"
                        value={values.json.apresentation.configs.columns}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.columns}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.columns}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Tempo de rotação da foto de destaque  (milesimos)</Form.Label>
                    <Form.Control
                        type="number"
                        step={1000}
                        name="json.apresentation.configs.transitionTime"
                        value={values.json.apresentation.configs.transitionTime}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.transitionTime}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.transitionTime}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Largura (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.width"
                        value={values.json.apresentation.configs.width}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.width}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.newImageWidth}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Altura (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.height"
                        value={values.json.apresentation.configs.height}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.height}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.height}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Margem (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.padding"
                        value={values.json.apresentation.configs.padding}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.padding}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.padding}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Foto de destaque</p>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Largura (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.newImageWidth"
                        value={values.json.apresentation.configs.newImageWidth}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.newImageWidth}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.newImageWidth}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Altura (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.newImageHeight"
                        value={values.json.apresentation.configs.newImageHeight}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.newImageHeight}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.newImageHeight}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Tempo de destaque (milesimos)</Form.Label>
                    <Form.Control
                        type="number"
                        step={1000}
                        name="json.apresentation.configs.newImageTotalTime"
                        value={values.json.apresentation.configs.newImageTotalTime}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.newImageTotalTime}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.newImageTotalTime}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                >
                    <Form.Check
                        checked={values.json.apresentation?.showQRCode.status === true ? true : false}
                        type="checkbox"
                        name="json.apresentation.showQRCode.status"
                        label="Mostrar QRCode"
                        onChange={(e) => {
                            setFieldValue(
                                "json.apresentation.showQRCode.status",
                                values.json.apresentation.showQRCode?.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.apresentation?.showQRCode?.status === true ? (
                        <Row>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"
                            >
                                <Form.Label>Texto</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.text"
                                    value={values.json.apresentation.showQRCode.text}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.text}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.text &&
                                        !errors.json?.apresentation?.showQRCode?.text
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"

                            >
                                <Form.Label>URL<OverlayTrigger overlay={<Tooltip>Caso nenhuma URL seja fornecida o QRCode irá redirecionar o usuário ao evento</Tooltip>}>
                                    <HelpTooltip icon={faExclamationCircle} />
                                </OverlayTrigger></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.link"
                                    value={values.json.apresentation.showQRCode.link}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.link}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.link &&
                                        !errors.json?.apresentation?.showQRCode?.link
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.link}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"

                            >
                                <Form.Label>Posição Y</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.y", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.y}>
                                    <option value={'top'}>
                                        Topo
                                    </option>
                                    <option value={'bottom'}>
                                        Fundo
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.y}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"

                            >
                                <Form.Label>Posição X</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.x", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.x}>
                                    <option value={'left'}>
                                        Esquerda
                                    </option>
                                    <option value={'right'}>
                                        Direita
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.x}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Media <span className="small">(Tamanho máximo: 10mb)</span>
                </h5>
            </Form.Group>
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    md="6">
                    <Card className=" mb-3">
                        <Card.Body>
                            <div className="text-center mb-3">
                                <div className="d-flex justify-content-center mb-3">
                                    <Form.Label>Frame</Form.Label>
                                </div>
                            </div>
                            <Form.Control
                                type="hidden"
                                name="json.apresentation.frame"
                                value={values.json.apresentation.frame}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.apresentation?.frame}
                                isValid={touched?.json?.apresentation?.frame && !errors?.json?.apresentation?.frame}
                            />
                            <div className="imagesUpload">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.frame ? "is-invalid" : ""}`}
                                    src={
                                        values.json.apresentation.frame !== "" && values.json.apresentation.frame !== null
                                            ? domainConfig.imageServer +
                                            "/customizacao/" +
                                            values.customizacao_id +
                                            "/" +
                                            values.json.apresentation.frame
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem footer aplicativo"
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.json?.apresentation?.frame}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="customizacao"
                                id={values.customizacao_id}
                                field="json.apresentation.frame"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            </Row>
        </>
    );
};

export const BackgroundFormComponent = ({ touched, errors, values, setFieldValue, handlePreviewSuccess, handlePreviewError }) => {

    switch (values.json.apresentation.textSide.background.type) {
        case 'image':
            return (
                <Form.Group
                    as={Col} className="mb-3">
                    <Card className="mb-0">
                        <Card.Body>
                            <Form.Control
                                type="hidden"
                                name="json.apresentation.textSide.background.url"
                                value={values.json.apresentation.textSide.background.url}
                                onChange={(e) => {
                                    setFieldValue("json.apresentation.textSide.background.url", e.target.value);
                                }}
                                isInvalid={!!errors?.json?.apresentation?.textSide?.background?.url}
                                isValid={touched?.json?.apresentation?.textSide?.background?.url && !errors?.json?.apresentation?.textSide?.background?.url}
                            />
                            <div className="imagesUpload">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.textSide?.background?.url ? "is-invalid" : ""}`}
                                    src={
                                        values.json.apresentation.textSide.background.url !== "" && values.json.apresentation.textSide.background.url !== null
                                            ? domainConfig.imageServer +
                                            "/customizacao/" +
                                            values.customizacao_id +
                                            "/" +
                                            values.json.apresentation.textSide.background.url
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem preview"
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.textSide?.background?.url}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="customizacao"
                                id={values.customizacao_id}
                                field="json.apresentation.textSide.background.url"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            )
        default:
            return ("")
    }
}

export default Gallery